<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>财务数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>电子钱包</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>所属校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <!-- <a-form-item class="block-line">
                        <a-select v-model="paramsData.type" allowClear placeholder="请选择支付类型" style="width:180px;">
                          <a-select-option v-for="(d, index) of tradeTypes" :key="index" :value="d['trade_type']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                    </a-form-item> -->
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group">
                <statistics :count='counts'/>
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <financeTotal :loading='loading' title="电子钱包统计 " :leads_data ='leads_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                </a-row>

                <div style="background:#fff;margin:16px 0">
                  <vue-element-loading :active="studioLoading" color="#00cca2" spinner="spinner"/>
                  <a-card size="small" title="校区电子钱包统计">
                    <!-- <a-button slot="extra" @click="toExportStudio" size="small" icon="export" :loading="exportLoading">导出</a-button> -->
                    <a-table size="small" :pagination="false" :bordered='false' rowKey="studio_id"
                    :columns="columns" :dataSource="list" :scroll="{ x: RelativeWidth, y:500 }">
                    </a-table>
                  </a-card>  
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges";
import moment from 'moment'
import financeTotal from './financeTotal'
import statistics from '@/views/bi/ranking/money/statistics'
export default {
  name: 'customer',
  components:{
    financeTotal,
    statistics
  },
  data() {
    return {
      loading:false,
      studioLoading:false,
      leads_data:{},
      columns:[],
      list:[],
      counts:[],
      studios:[],
      tradeTypes:[],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        studio_id:'',
        filter_type:'day'
      },
    }
  },
  mixins: [ranges],
  computed: {
        RelativeWidth() {
            let width = 800
            if(this.filedLength >10){
              width = 800 + ( this.filedLength - 10 ) * 125
            }
            return width
        }
  },
  created() {
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getTradeType()
    this.getStudio()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.loading = true
      this.getCount(obj)
      let res = await this.$store.dispatch('analysisWalletAction', obj)
      this.leads_data = res.data
      this.loading = false
    },
    async getStudioWallet(obj){
      this.studioLoading = true
      let res = await this.$store.dispatch('analysisWalletStudioAction', obj)

      let list = res.data.studio_list.rows
      list.forEach(item=>{
          item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total) : item.total
          item.filed.forEach(it=>{
              item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
          })
      })
      let week = [
          { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
          { title: '校区名称', width:'160px', dataIndex: 'studio_name',  key: 'studio_name', scopedSlots: { customRender: 'name' },ellipsis:true, fixed: 'left'},
          { title: '合计', width:'120px', dataIndex: 'total', key: 'total',fixed: 'left',align:'right'},
          { title: '占比', width:'80px', dataIndex: 'precent', key: 'precent',fixed: 'left',align:'right'},
      ]
      if(list[0]){
        list[0].filed.forEach(item=>{
            let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right',width:'110px',sorter: (a, b) => Number(a[item.filed_name].replace(/,/g, '')) - Number(b[item.filed_name].replace(/,/g, ''))}
            week.push(obj)
        })
      }
      this.columns = week
      this.list = res.data.studio_list.rows
      this.studioLoading = false
    },
    async getTradeType() {
      let res = await this.$store.dispatch('searchTradeTypeAction', {})
      this.tradeTypes = res.data
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisWalletCountAction', obj)
      this.counts = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen();
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
      this.getStudioWallet(obj)
    }
  }
}
</script>

